<template>
  <b-row>
    <b-col cols="12" md="6" lg="9">
      <b-card>
        <template>
          <div class="d-flex align-items-center text-center justify-content-center">
            <div>
              <h4>الاشتراكات</h4>
              <unicon
                class="my-1"
                name="ticket"
                fill="#5E5873"
                width="100"
                height="30"
              ></unicon>
              <h4>
                <strong>({{ subjectForm.subscriptionsCount }})</strong>
              </h4>
            </div>
            <div class="border-right border-left">
              <h4>الوحدات</h4>
              <unicon
                class="my-1"
                name="notebooks"
                fill="#5E5873"
                width="100"
                height="30"
              ></unicon>
              <h4>
                <strong>({{ subjectForm.unitsCount }})</strong>
              </h4>
            </div>
            <div>
              <h4>الدروس</h4>
              <unicon
                class="my-1"
                name="bookmark"
                fill="#5E5873"
                width="100"
                height="30"
              ></unicon>
              <h4>
                <strong>({{ subjectForm.lessonsCount }})</strong>
              </h4>
            </div>
          </div>
          <div class="text-center border rounded mt-1 p-1">
            <div class="float-left my-1">
              <h3>الوحدات</h3>
            </div>
            <div class="float-right">
              <b-button
                to="/subjects-units/units"
                variant="primary"
                size="sm"
                class="my-1 d-flex align-items-center text-nowrap"
              >
                وحدة جديدة
                <unicon
                  width="18"
                  height="20"
                  name="plus"
                  class="pl-1"
                  fill="#fff"
                ></unicon>
              </b-button>
            </div>
            <b-table responsive :items="unitsListActivePage" :fields="fields" show-empty>
              <template #empty>
                <h4>لا يوجد وحدات لهذه المادة</h4>
              </template>
              <template #cell(publishTime)="data">
                {{ new Date(data.item.publishTime).toISOString().substr(0, 10) }}
              </template>
              <template #cell(تفاصيل)="data">
                <b-button
                  :to="`/subjects-units/units/details/${data.item.id}`"
                  size="sm"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  style="padding: 2px 6px !important"
                >
                  <unicon name="arrow-left" fill="#7367f0" width="25"></unicon>
                </b-button>
              </template>
            </b-table>
            <b-col
              v-if="subjectForm.subjectUnits.length > 0"
              cols="12"
              class="d-flex justify-content-center"
            >
              <b-pagination
                :value="unitsListFirstPage"
                :total-rows="subjectForm.subjectUnits.length"
                :per-page="unitListPageLength"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => unitsListPagination(value)"
              >
                <template #prev-text>
                  <unicon width="20" name="angle-right" fill="royalblue" />
                </template>
                <template #next-text>
                  <unicon width="20" name="angle-right" fill="royalblue" />
                </template>
              </b-pagination>
            </b-col>
          </div>
        </template>
      </b-card>
    </b-col>
    <b-col cols="12" md="6" lg="3">
      <b-card no-body>
        <b-card-body>
          <validation-observer ref="updateSubject">
            <b-form>
              <b-form-group label="اسم المادة" label-for="subjectName">
                <validation-provider
                  v-slot="{ errors }"
                  name="اسم المادة"
                  rules="required"
                >
                  <b-form-input
                    id="subjectName"
                    v-model="subjectForm.name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="غلاف المادة" label-for="subjectImage">
                <fileDragDrop
                  @inValidFile="inValidSubjectImage"
                  @uploaded="uploadSubjectImage"
                  id="subjectImage"
                  height="100px"
                  type="image"
                  title="أدرج الصورة هنا أو انقر للتحميل"
                />
                <img
                  :src="
                    !subjectForm.imageDto && !subjectForm.imagePath
                      ? '/media/placeholder/default_image.png'
                      : !!subjectForm.imageDto
                      ? subjectForm.imageDto.base64
                      : subjectForm.imagePath
                  "
                  style="max-height: 320px; object-fit: fill"
                  class="w-100 my-2"
                />
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-card-body>
        <b-card-footer class="d-flex">
          <b-button
            size="md"
            type="submit"
            variant="primary"
            class="mr-1"
            @click.prevent="SubmitUpdateSubject()"
          >
            تعديل
          </b-button>
          <b-button
            class="mr-1"
            to="/subjects-units/subjects/"
            size="md"
            variant="outline-primary"
          >
            تراجع
          </b-button>
          <b-button
            @click.prevent="SubmitRemoveSubject()"
            size="md"
            class="ml-auto"
            variant="danger"
          >
            حذف
          </b-button>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<style scoped>
.card {
  min-height: 67vh;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BCard,
  BCardBody,
  BCardFooter,
  BTable,
  BPagination,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@validations";
import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
import Swal from "sweetalert2";
export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    fileDragDrop,
    BRow,
    BCol,
    BForm,
    BCard,
    BCardBody,
    BCardFooter,
    BTable,
    BPagination,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      fields: [
        {
          label: "اسم الوحدة",
          key: "name",
        },
        { label: "تاريخ الإضافة", key: "publishTime" },
        { label: "عدد الاشتراكات", key: "subscriptionsCount" },
        "تفاصيل",
      ],
    };
  },
  props: {
    id: String,
  },
  mounted() {
    localize("ar");
    this.fetchSubjectDetails(this.id);
  },
  computed: {
    ...mapGetters([
      "subjectForm",
      "unitsListActivePage",
      "unitsListFirstPage",
      "unitListPageLength",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchSubjectDetails",
      "setSubject",
      "removeSubject",
      "unitsListPagination",
    ]),
    SubmitUpdateSubject() {
      this.$refs.updateSubject.validate().then((success) => {
        if (success) {
          this.setSubject(this.subjectForm).then(() => {
            this.$bvToast.toast(`تم تعديل المادة ${this.subjectForm.name} بنجاح`, {
              title: "تم التعديل",
              variant: "success",
              toaster: "b-toaster-bottom-left",
              solid: true,
              autoHideDelay: 2000,
              appendToast: true,
            });
          });
        }
      });
    },
    SubmitRemoveSubject() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل فعلاً تريد حذف المادة ؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            this.removeSubject(this.subjectForm);
            this.$router.push({ name: "subjects units" });
          }
        });
    },
    uploadSubjectImage(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (() => {
        this.subjectForm.imageDto = {
          base64: reader.result,
          contentType: file.type,
          fileName: file.name,
          length: file.size,
        };
      }).bind(this);
    },
    inValidSubjectImage() {
      console.log("invalid file type");
    },
  },
};
</script>
